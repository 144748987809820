import React from 'react';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import styled, { css } from 'styled-components/macro'; //eslint-disable-line
import ProfileCard from 'components/profile/ProfileCard';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { PrimaryLink } from 'components/misc/Links';
import PersonalInformation from 'components/profile/PersonalInformation';
import InfoBox from 'components/profile/InfoBox';
import { CheckCircleIcon, ClockIcon , ViewBoardsIcon } from '@heroicons/react/outline';
import QuizTableList from 'components/profile/QuizTableList';
import ProfileExperience from 'components/profile/ProfileExperience';

const Container = tw.div`container mx-auto`;
const Header = styled.div`
  ${tw`flex space-x-6 items-center`}
  h2 {
    ${tw`text-2xl font-bold`}
  }
`;
const Grid = tw.div`lg:grid lg:gap-6 space-y-4 lg:space-y-0 lg:grid-cols-6 mt-4`;
const LeftSection = tw.div`lg:col-span-2 space-y-6`;
const RightSection = tw.div`lg:col-span-4 space-y-6`;
const InfoBoxContainer = tw.div`md:flex justify-between md:space-x-6 space-y-3 md:space-y-0`;

const Wrapper = tw.div`bg-gray-200`;

export default function Dashboard() {
  return (
    <Wrapper>
      <AnimationRevealPage>
        <Container>
          <Header>
            <PrimaryLink to="/">
              <ArrowNarrowLeftIcon tw="w-6" />
            </PrimaryLink>
            <h2>Profile</h2>
          </Header>
          <Grid>
            <LeftSection>
              <ProfileCard />
              <PersonalInformation />
              <ProfileExperience />
            </LeftSection>
            <RightSection>
              <InfoBoxContainer>
                <InfoBox
                  title="completed Projects"
                  count="125"
                  icon={<CheckCircleIcon />}
                />
                <InfoBox
                  title="Pending Projects"
                  count="12"
                  icon={<ClockIcon />}
                />
                <InfoBox
                  title="Total Revenue"
                  count="$36,524"
                  icon={<ViewBoardsIcon />}
                />
              </InfoBoxContainer>
              <QuizTableList />
            </RightSection>
          </Grid>
        </Container>
      </AnimationRevealPage>
    </Wrapper>
  );
}
