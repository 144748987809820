import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import tw from 'twin.macro';
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';


const QuizStatus = tw.span`text-xs p-1 text-white rounded`;
const UserAvatar = styled(Avatar)`
  width: 2rem !important;
  height: 2rem !important;
`;
const AvatarLink = tw.a`flex items-center space-x-2 hover:text-primary-500 transition duration-100`;
export const ActionLink = tw(Link)`px-3 py-1 w-16 rounded-full text-xs flex items-center space-x-1 font-semibold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

export default function QuizTableRow({ quiz }) {
  return (
    <TableRow>
      <TableCell>#{quiz.id}</TableCell>
      <TableCell>{quiz.category}</TableCell>
      <TableCell>{quiz.partisipants}</TableCell>
      <TableCell>{formatQuizStatus(quiz.status)}</TableCell>
      <TableCell>
        <AvatarLink href="#">
          <UserAvatar alt={quiz.topScorer.name} src={quiz.topScorer.avatar} />
          <span tw="text-xs flex">{quiz.topScorer.name}, {quiz.topScorer.score}</span>
        </AvatarLink>
      </TableCell>
      <TableCell>
        <ActionLink to="/">
          <span>go</span>
          <ArrowNarrowRightIcon tw="w-4 h-4" />
        </ActionLink>
      </TableCell>
    </TableRow>
  );
}

const formatQuizStatus = status => {
  const styles = { completed: tw`bg-green-500`, running: tw`bg-yellow-600` };
  return <QuizStatus css={styles[status.toLowerCase()]}>{status}</QuizStatus>;
};
