import React from 'react';
import tw from 'twin.macro';
// import { CheckCircleIcon } from '@heroicons/react/outline';
import styled from 'styled-components';

const Wrapper = tw.div`bg-white p-4 flex justify-between items-center rounded w-full`;
const IconContainer = styled.span`
  ${tw`rounded-full bg-primary-500 p-2 text-white`}
  svg {
    ${tw`w-8 h-8`}
  }
`;

export default function InfoBox({ title, count, icon }) {
  return (
    <Wrapper>
      <div>
        <span tw="text-sm text-black">{title}</span>
        <h4 tw="font-bold text-lg mt-2">{count}</h4>
      </div>
      <IconContainer>{icon}</IconContainer>
    </Wrapper>
  );
}
