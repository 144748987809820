import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = tw.div`p-4 md:p-6 rounded bg-white space-y-4`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 20px;
`;

export default function PersonalInformation() {
  return (
    <Wrapper>
      <h4 tw="text-base font-bold text-black">Personal Information</h4>
      <p tw="text-sm text-gray-600">
        Hi I'm Cynthia Price,has been the industry's standard dummy text To an
        English person, it will seem like simplified English, as a skeptical
        Cambridge.
      </p>
      <Table tw="mx-4">
        <tr tw="border-b border-gray-300 py-8">
          <td tw="text-black font-semibold text-sm">Full Name :</td>
          <td tw="text-sm text-gray-700">Cynthia Price</td>
        </tr>
        <tr tw="border-b border-gray-300 py-8">
          <td tw="text-black font-semibold text-sm">Mobile :</td>
          <td tw="text-sm text-gray-700">(123) 123 1234</td>
        </tr>
        <tr tw="border-b border-gray-300 py-8">
          <td tw="text-black font-semibold text-sm">E-mail :</td>
          <td tw="text-sm text-gray-700">cynthiaskote@gmail.com</td>
        </tr>
        <tr tw="border-b border-gray-300 py-8">
          <td tw="text-black font-semibold text-sm">Location :</td>
          <td tw="text-sm text-gray-700">California, United States</td>
        </tr>
      </Table>
    </Wrapper>
  );
}
