import React from 'react';
import tw from 'twin.macro';
import { DatabaseIcon, CodeIcon, PencilAltIcon  } from '@heroicons/react/outline';

const Wrapper = tw.div`rounded bg-white p-4`;

export default function ProfileExperience() {
  return (
    <Wrapper>
      <h4 tw="text-base font-bold mb-6 text-black">Experience</h4>
      <div tw="ml-4 space-y-4">
        <div tw="flex items-start space-x-4">
          <DatabaseIcon tw="w-8 text-primary-500" />
          <div tw="flex flex-col space-y-1 ">
            <span tw="font-semibold text-base">Backend Developer</span>
            <span tw="text-gray-600 text-xs">2016 - 21</span>
          </div>
        </div>
        <div tw="flex items-start space-x-4">
          <CodeIcon tw="w-8 text-primary-500" />
          <div tw="flex flex-col space-y-1 ">
            <span tw="font-semibold text-base">Frontend Developer</span>
            <span tw="text-gray-600 text-xs">2016 - 21</span>
          </div>
        </div>
        <div tw="flex items-start space-x-4">
          <PencilAltIcon tw="w-8 text-primary-500" />
          <div tw="flex flex-col space-y-1  ">
            <span tw="font-semibold text-base">Backend Developer</span>
            <span tw="text-gray-600 text-xs">2016 - 21</span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
