import React from 'react';
import tw from 'twin.macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import QuizTableRow from './QuizTableRow';

const Wrapper = tw.div`rounded p-4 bg-white`;

function createData(id, category, partisipants, status, topScorer) {
  return { id, category, partisipants, status, topScorer };
}

const rows = [
  createData('323', 'education', 23, 'completed', {
    name: 'user name',
    score: '83%',
    avatar: '/avatar.jpg'
  }),
  createData('434', 'covid 19', 12, 'completed', {
    name: 'Shakil Hossain',
    score: '93%',
    avatar: 'none'
  }),
  createData('844', 'sports', 0, 'running', {
    name: 'Rahim',
    score: '75%',
    avatar: 'none'
  }),
  createData('84324', 'sports', 0, 'running', {
    name: 'Rahim',
    score: '75%',
    avatar: 'none'
  }),
];

export default function QuizTableList() {
  return (
    <Wrapper>
      <h3 tw="text-base text-black font-bold mb-4">All Quizzes</h3>
      <div tw="overflow-x-auto">
        <Table tw="min-w-full">
          <TableHead>
            <TableRow>
              <TableCell>
                <span tw="font-bold">Quiz ID</span>
              </TableCell>
              <TableCell>
                <span tw="font-bold">Category</span>
              </TableCell>
              <TableCell>
                <span tw="font-bold">Total Participants</span>
              </TableCell>
              <TableCell>
                <span tw="font-bold">Status</span>
              </TableCell>
              <TableCell>
                <span tw="font-bold">Top Scores</span>
              </TableCell>
              <TableCell>
                <span tw="font-bold">Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <QuizTableRow key={row.id} quiz={row} />
            ))}
          </TableBody>
        </Table>
      </div>
    </Wrapper>
  );
}
