import React from 'react';
import profileImgUrl from 'images/profile-img.png';
import profileAvatarUrl from 'images/avatar.jpg';
import tw from 'twin.macro';
import styled from 'styled-components';
import { PrimaryIconBtn } from 'components/misc/Buttons';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';

const Wrapper = tw.div`bg-white rounded-lg overflow-hidden`;

const TopArea = styled.div`
  ${tw`h-32 bg-primary-100 p-4 relative w-full`}
  .profile-img {
    ${tw`max-w-full max-h-full absolute top-0 right-0`}
  }
  .profile-avatar {
    ${tw`rounded-full absolute w-16 h-16 border-4 border-white bottom-0 transform translate-y-1/2`}
  }
  h3 {
    ${tw`text-base font-semibold text-primary-900`}
  }
  p {
    ${tw`text-xs mt-2 text-primary-800`}
  }
`;

const BottomArea = styled.div`
  ${tw`p-4 flex justify-start items-end space-x-4 pt-4`}

  .left {
    ${tw`space-y-3 w-48`}
    h4 {
      ${tw`text-base font-medium text-black`}
    }
    span {
      ${tw`text-xs text-black`}
    }
  }
  .right {
    ${tw`w-full`}
  }
`;

export default function ProfileCard() {
  return (
    <Wrapper>
      <TopArea className="top">
        <img className="profile-img" src={profileImgUrl} alt="Hello" />
        <img className="profile-avatar" src={profileAvatarUrl} alt="Hello" />
        <h3>Welcome Back !</h3>
        <p>it will seem like simplified</p>
      </TopArea>
      <BottomArea>
        <div className="left">
          <h4>Cynthia Price</h4>
          <span>UI/UX Designer</span>
        </div>
        <div className="right">
          <div tw="flex w-full space-x-12">
            <span tw="space-y-3">
              <h4 tw="font-semibold text-base">125</h4>
              <span tw="text-sm">Projects</span>
            </span>
            <span tw="space-y-3">
              <h4 tw="font-semibold text-base">$1245</h4>
              <span tw="text-sm">Revinue</span>
            </span>
          </div>
          <PrimaryIconBtn tw="mt-4">
            <span>Edit Profile</span>
            <ArrowNarrowRightIcon tw="w-4" />
          </PrimaryIconBtn>
        </div>
      </BottomArea>
    </Wrapper>
  );
}
